<template>
  <div class="px-2 py-5">
    <h1 class="title">Dermatitis atopica EASI</h1>
    <b-message type="is-info">
      Seleccione el % afectado de cada parte del cuerpo en los apartados
      inferiores y a continuación la intensidad de los diferentes síntomas
      detectados en el/la paciente.
    </b-message>

    <h1 class="title has-text-centered">Cabeza y cuello</h1>
    <EasiDefaultForm
      areaPercentage="0.1"
      module="cabeza"
      :question="questions.HEAD"
      :answers="answers.HEAD"
      :temporalData="temporal.head"
      :queryMode="queryMode"
      :queryAnswers="headQueryAnsers"
      @formInfo="setInfo"
    />
    <hr />
    <h1 class="title has-text-centered">Tronco</h1>
    <EasiDefaultForm
      areaPercentage="0.3"
      module="tronco"
      @formInfo="setInfo"
      :question="questions.TRUK"
      :answers="answers.TRUK"
      :temporalData="temporal.truk"
      :queryAnswers="trukQueryAnsers"
      :queryMode="queryMode"
    />
    <hr />
    <h1 class="title has-text-centered">Extremidades superiores</h1>
    <EasiDefaultForm
      areaPercentage="0.2"
      module="extremidadesSuperiores"
      :question="questions.SUPERIOR_LIMBS"
      :answers="answers.SUPERIOR_LIMBS"
      :temporalData="temporal.superiorLimbs"
      :queryMode="queryMode"
      :queryAnswers="superiorQueryAnsers"
      @formInfo="setInfo"
    />
    <hr />
    <h1 class="title has-text-centered">Extremidades inferiores</h1>
    <EasiDefaultForm
      areaPercentage="0.4"
      module="extremidadesInferiores"
      :question="questions.LOWER_LIMBS"
      :answers="answers.LOWER_LIMBS"
      :temporalData="temporal.lowerLimbs"
      :queryMode="queryMode"
      :queryAnswers="lowerQueryAnsers"
      @formInfo="setInfo"
    />

    <b-message type="is-info mt-6">
      <strong>Rango de resultados</strong>
      <br />
      0 - Nada
      <br />
      0.1 a 1 - Casi nada
      <br />
      1.1 a 7 - Leve
      <br />
      7.1 a 21 - Moderado
      <br />
      21 a 50 - Severo
      <br />
      50.1 a 72 Muy severo
    </b-message>

    <div class="block has-text-centered title mt-6" v-if="!queryMode">
      El resultado es: {{ score }}
      <span v-if="isIncompleted">({{ scoreText }})</span>
    </div>

    <div class="block has-text-centered title mt-6" v-if="queryMode">
      El resultado es: {{ result }}
      <span>({{ resultScoreText }})</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
    <!-- <section>
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <b-step-item
          step="1"
          label="Cabeza y cuello"
          :clickable="isStepsClickable"
          :type="isHeadAndNeckComplete ? 'is-success' : 'is-info'"
        >
          
        </b-step-item>

        <b-step-item
          step="2"
          label="Tronco"
          :clickable="isStepsClickable"
          :type="isTrukComplete ? 'is-success' : 'is-info'"
        >
          
        </b-step-item>

        <b-step-item
          step="3"
          label="Extremidades superiores"
          :clickable="isStepsClickable"
          :type="isSuperiorLimbsComplete ? 'is-success' : 'is-info'"
        >
          
        </b-step-item>

        <b-step-item
          step="4"
          label="Extremidades inferiores"
          :clickable="isStepsClickable"
          :type="isLoweLimbsComplete ? 'is-success' : 'is-info'"
          disabled
        >
          
        </b-step-item>

        <template v-if="customNavigation" #navigation="{previous, next}">
          <b-message type="is-info">
            <strong>Rango de resultados</strong>
            <br />
            0 - Nada
            <br />
            0.1 a 1 - Casi nada
            <br />
            1.1 a 7 - Leve
            <br />
            7.1 a 21 - Moderado
            <br />
            21 a 50 - Severo
            <br />
            50.1 a 72 Muy severo
          </b-message>
          <div class="block has-text-centered title mt-5" v-if="!queryMode">
            El resultado es: {{ score }}
            <span v-if="isIncompleted">({{ scoreText }})</span>
          </div>

          <div class="block has-text-centered title mt-5" v-if="queryMode">
            El resultado es: {{ result }}
            <span>({{ resultScoreText }})</span>
          </div>
          <div class="buttons is-centered mt-5">
            <b-button
              icon-left="chevron-left"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
              class="prev-button"
            >
              Anterior
            </b-button>
            <b-button
              icon-right="check-bold"
              :disabled="!next.disabled"
              v-if="next.disabled && !queryMode"
              @click.prevent="validate"
              class="has-text-rigth save-button"
            >
              Guardar
            </b-button>
            <b-button
              class="clear-button has-text-rigth"
              icon-right="block-helper"
              v-if="!queryMode"
              @click.prevent="clear"
            >
              Limpiar
            </b-button>

            <b-button
              icon-right="chevron-right"
              :disabled="next.disabled"
              @click.prevent="next.action"
              class="has-text-rigth next-button"
            >
              Siguiente
            </b-button>
          </div>
        </template>
      </b-steps>
    </section> -->
  </div>
</template>

<script>
import { EASI as questions } from "../../../data/questions";
import { EASI as answers } from "../../../data/answers";
import EasiDefaultForm from "../../../components/Forms/EasiDefaultForm.vue";

export default {
  components: { EasiDefaultForm },
  data() {
    return {
      questions: questions,
      answers: answers,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: true,
      isHeadAndNeckComplete: false,
      isTrukComplete: false,
      isSuperiorLimbsComplete: false,
      isLoweLimbsComplete: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      headModule: 0,
      trukModule: 0,
      superiorLimbs: 0,
      loweLimbs: 0,
      headAnswers: [],
      trukAnswers: [],
      superiorLimbsAnswers: [],
      loweLimbsAnswers: [],
      answersConsolidate: [],
      age: 1,
      temporal: {},
      queryMode: false,
      headQueryAnsers: [],
      trukQueryAnsers: [],
      superiorQueryAnsers: [],
      lowerQueryAnsers: [],
      result: "",
      resultScoreText: "",
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "EasiList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.temporal = this.$route.params.temporalData;
    }
  },
  computed: {
    score() {
      let result = parseFloat(
        this.headModule + this.trukModule + this.superiorLimbs + this.loweLimbs
      ).toFixed(1);

      if (result == 0.0) {
        return 0;
      } else {
        return result;
      }
    },
    scoreText() {
      let text = "";

      if (parseInt(this.score) == 0) {
        text = "Nada";
      }

      if (this.score >= 0.1 && this.score <= 1) {
        text = "Casi nada";
      }

      if (this.score >= 1.1 && this.score <= 7) {
        text = "Leve";
      }

      if (this.score >= 7.1 && this.score <= 21) {
        text = "Moderado";
      }

      if (this.score >= 21 && this.score <= 50) {
        text = "Severo";
      }

      if (this.score >= 50 && this.score <= 72) {
        text = "Muy severo";
      }

      return text;
    },
    isIncompleted() {
      return (
        this.isHeadAndNeckComplete == false ||
        this.isTrukComplete == false ||
        this.isSuperiorLimbsComplete == false ||
        this.isLoweLimbsComplete == false
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    setInfo(moduleData) {
      switch (moduleData.module) {
        case "cabeza":
          this.headModule = moduleData.score;
          this.headAnswers = moduleData.answers;
          this.temporal.head = moduleData.answers;
          this.isHeadAndNeckComplete = moduleData.isComplete;
          break;
        case "tronco":
          this.trukModule = moduleData.score;
          this.trukAnswers = moduleData.answers;
          this.temporal.truk = moduleData.answers;
          this.isTrukComplete = moduleData.isComplete;
          break;
        case "extremidadesSuperiores":
          this.superiorLimbs = moduleData.score;
          this.superiorLimbsAnswers = moduleData.answers;
          this.temporal.superiorLimbs = moduleData.answers;
          this.isSuperiorLimbsComplete = moduleData.isComplete;
          break;
        case "extremidadesInferiores":
          this.loweLimbs = moduleData.score;
          this.loweLimbsAnswers = moduleData.answers;
          this.temporal.lowerLimbs = moduleData.answers;
          this.isLoweLimbsComplete = moduleData.isComplete;
          break;
        default:
          break;
      }
      this.saveTermporalSulrvey();
    },
    cleanForms() {
      this.isHeadAndNeckComplete = false;
      this.isTrukComplete = false;
      this.isSuperiorLimbsComplete = false;
      this.isLoweLimbsComplete = false;
      this.activeStep = 0;
      this.answersConsolidate = [];
      this.headAnswers = [];
      this.trukAnswers = [];
      this.loweLimbsAnswers = [];
      this.superiorLimbsAnswers = [];
      this.$global.$emit("clearForm");
    },
    clear() {
      this.cleanForms();
      this.clearTemporalSurveys();
      this.showUndoDialog("Se limpiaron los datos de  la encuesta EASI.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        this.setAnswers();
        let data = {
          surveyCode: "EASI",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.answersConsolidate,
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      this.headAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });

      this.trukAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });

      this.loweLimbsAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });

      this.superiorLimbsAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "EASI",
          route: "Easi",
        },
        survey: {
          head: this.temporal.head,
          truk: this.temporal.truk,
          superiorLimbs: this.temporal.superiorLimbs,
          lowerLimbs: this.temporal.lowerLimbs,
        },
      });
    },
    setEvaluationAnswers(data) {
      this.result = data.result;
      this.resultScoreText = data.appreciation;
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code.includes("HEAD")) {
          this.headQueryAnsers.push(survey);
        }

        if (survey.question.code.includes("TRUK")) {
          this.trukQueryAnsers.push(survey);
        }

        if (survey.question.code.includes("SUPERIOR")) {
          this.superiorQueryAnsers.push(survey);
        }

        if (survey.question.code.includes("LOWER")) {
          this.lowerQueryAnsers.push(survey);
        }
      });
      this.$global.$emit("setQueryAnswers");
    },
  },
};
</script>
