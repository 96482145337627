<template>
  <div>
    <div class="block">
      <span>
        Porcentaje de área de la piel implicada.
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[0][1]"
          >
            0%
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[1][1]"
          >
            1–9%
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[2][1]"
          >
            10–29%
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[3][1]"
          >
            30–49%
          </b-radio>
        </div>

        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[4][1]"
          >
            50–69%
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[5][1]"
          >
            70–89%
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[6][1]"
          >
            90–100%
          </b-radio>
        </div>
      </div>
    </div>

    <div class="block">
      <span>
        ERITEMA
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="erythemaAnswers[0][1]"
          >
            0
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="erythemaAnswers[1][1]"
          >
            1
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="erythemaAnswers[2][1]"
          >
            2
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="erythemaAnswers[3][1]"
          >
            3
          </b-radio>
        </div>
      </div>
    </div>
    <div class="block">
      <span>
        EDEMA
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="edema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="edemaAnswers[0][1]"
          >
            0
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="edema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="edemaAnswers[1][1]"
          >
            1
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="edema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="edemaAnswers[2][1]"
          >
            2
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="edema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="edemaAnswers[3][1]"
          >
            3
          </b-radio>
        </div>
      </div>
    </div>
    <div class="block">
      <span>
        EXCORIACION
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="excoriation"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="edemaAnswers[0][1]"
          >
            0
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="excoriation"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="edemaAnswers[1][1]"
          >
            1
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="excoriation"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="edemaAnswers[2][1]"
          >
            2
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="excoriation"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="edemaAnswers[3][1]"
          >
            3
          </b-radio>
        </div>
      </div>
    </div>
    <div class="block">
      <span>
        LIQUENIFICACION
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="liquenification"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="liquenificationAnswers[0][1]"
          >
            0
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="liquenification"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="liquenificationAnswers[1][1]"
          >
            1
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="liquenification"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="liquenificationAnswers[2][1]"
          >
            2
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="liquenification"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="liquenificationAnswers[3][1]"
          >
            3
          </b-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    areaPercentage: {
      type: String,
      default: "0.0",
    },
    module: {
      type: String,
      default: "Cabeza",
    },
    question: {
      type: Object,
      default: null,
    },
    answers: {
      type: Object,
      default: null,
    },
    temporalData: {
      type: Array,
      default: null,
    },
    queryMode: {
      type: Boolean,
      default: false,
    },
    queryAnswers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      grade1Area: "< el 10% de área implicada",
      area: "",
      severity: "",
      erythema: "",
      edema: "",
      excoriation: "",
      liquenification: "",
      questionKeys: [],
      areaAnswers: [],
      erythemaAnswers: [],
      edemaAnswers: [],
      excoriationAnswers: [],
      liquenificationAnswers: [],
      restoreData: {},
    };
  },
  created() {
    this.setKeys();

    this.$global.$on("setQueryAnswers", () => {
      this.setQueryAnswers();
    });

    if (this.temporalData && this.temporalData.length > 0) {
      this.area = this.temporalData[0].value;
      this.erythema = this.temporalData[1].value;
      this.edema = this.temporalData[2].value;
      this.excoriation = this.temporalData[3].value;
      this.liquenification = this.temporalData[4].value;

      this.emitData();
    }

    if (!this.temporalData) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });

    this.$global.$on("clearForm", () => {
      this.clear();
    });
  },
  computed: {
    score() {
      let calculate =
        parseInt(this.edema || 0) +
        parseInt(this.erythema || 0) +
        parseInt(this.excoriation || 0) +
        parseInt(this.liquenification || 0);
      return (
        calculate * parseInt(this.area || 0) * parseFloat(this.areaPercentage)
      );
    },
    isIncompleted() {
      return (
        this.erythema === "" ||
        this.edema === "" ||
        this.excoriation === "" ||
        this.liquenification === "" ||
        this.area === ""
      );
    },
  },
  methods: {
    setKeys() {
      let areaKey = Object.keys(this.answers)[0];
      let erythemaKey = Object.keys(this.answers)[1];
      let edemaKey = Object.keys(this.answers)[2];
      let excoriationKey = Object.keys(this.answers)[3];
      let liquenificationKey = Object.keys(this.answers)[4];

      this.questionKeys = Object.keys(this.question);

      this.areaAnswers = Object.entries(this.answers[areaKey]);
      this.erythemaAnswers = Object.entries(this.answers[erythemaKey]);
      this.edemaAnswers = Object.entries(this.answers[edemaKey]);
      this.excoriationAnswers = Object.entries(this.answers[excoriationKey]);
      this.liquenificationAnswers = Object.entries(
        this.answers[liquenificationKey]
      );
    },
    clear() {
      this.restoreData.erythema = this.erythema;
      this.restoreData.edema = this.edema;
      this.restoreData.excoriation = this.excoriation;
      this.restoreData.liquenification = this.liquenification;
      this.restoreData.area = this.area;

      // this.erythema = "";
      // this.edema = "";
      // this.excoriation = "";
      // this.liquenification = "";
      // this.area = "";
      this.initialAnswer();
    },
    emitData() {
      this.$emit("formInfo", {
        score: this.score,
        module: this.module,
        isComplete: !this.isIncompleted,
        answers: this.setAnswers(),
      });
    },
    setAnswers() {
      let area = null;
      let erythema = null;
      let edema = null;
      let excoriation = null;
      let liquenification = null;

      this.areaAnswers.forEach((element) => {
        if (element[1] == this.area) {
          area = {
            questionCode: Object.keys(this.answers)[0],
            answerCode: element[0],
            value: this.area,
          };
        }
      });

      this.erythemaAnswers.forEach((element) => {
        if (element[1] == this.erythema) {
          erythema = {
            questionCode: Object.keys(this.answers)[1],
            answerCode: element[0],
            value: this.erythema,
          };
        }
      });

      this.edemaAnswers.forEach((element) => {
        if (element[1] == this.edema) {
          edema = {
            questionCode: Object.keys(this.answers)[2],
            answerCode: element[0],
            value: this.edema,
          };
        }
      });

      this.excoriationAnswers.forEach((element) => {
        if (element[1] == this.excoriation) {
          excoriation = {
            questionCode: Object.keys(this.answers)[3],
            answerCode: element[0],
            value: this.excoriation,
          };
        }
      });

      this.areaAnswers.forEach((element) => {
        if (element[1] == this.liquenification) {
          liquenification = {
            questionCode: Object.keys(this.answers)[4],
            answerCode: element[0],
            value: this.liquenification,
          };
        }
      });

      return [area, erythema, edema, excoriation, liquenification];
    },
    restoreForm() {
      this.erythema = this.restoreData.erythema;
      this.edema = this.restoreData.edema;
      this.excoriation = this.restoreData.excoriation;
      this.liquenification = this.restoreData.liquenification;
      this.area = this.restoreData.area;
      this.emitData();
    },
    setQueryAnswers() {
      this.queryAnswers.forEach((survey) => {
        if (survey.question.code.includes("AREA")) {
          this.area = survey.value;
        }

        if (survey.question.code.includes("ERITEMA")) {
          this.erythema = survey.value;
        }

        if (survey.question.code.includes("EDEMA")) {
          this.edema = survey.value;
        }

        if (survey.question.code.includes("EXCORIACION")) {
          this.excoriation = survey.value;
        }

        if (survey.question.code.includes("LIQUENIFICACION")) {
          this.liquenification = survey.value;
        }
      });

      // this.emitData();
    },
    initialAnswer() {
      this.area = this.areaAnswers[0][1];
      this.erythema = this.erythemaAnswers[0][1];
      this.edema = this.edemaAnswers[0][1];
      this.excoriation = this.excoriationAnswers[0][1];
      this.liquenification = this.liquenificationAnswers[0][1];
      this.emitData();
    },
  },
};
</script>

<style></style>
